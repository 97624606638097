import React, { useEffect } from 'react';
import enFlag from './icons/enFlag.png';
import deFlag from './icons/deFlag.png';
import css from './LanguageSwitcher.module.css';

import { Menu, MenuLabel, MenuContent, MenuItem } from '../../components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { handleSwitchLanguage } from '../../ducks/UI.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

const LanguageSwitcherComponent = props => {
  const { onHandleSwitchNewLanguage, currentUser, language, onUpdateProfile, onMobile } = props;
  //set language to saved value if any
  useEffect(() => {
    const savedLanguageMaybe =
      currentUser?.attributes?.profile?.protectedData?.language ||
      window.sessionStorage.getItem('sessionLanguage') ||
      language;
    if (language !== savedLanguageMaybe) {
      onHandleSwitchNewLanguage(savedLanguageMaybe);
    }
  }, []);

  const handleLanguageSwitch = async lang => {
    console.log('language switch');
    //change language state
    await onHandleSwitchNewLanguage(lang);

    //update currentUser
    if (currentUser?.id?.uuid) {
      await onUpdateProfile({
        protectedData: {
          language: lang,
        },
      });
    } else {
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem('sessionLanguage', lang);
      }
    }
  };

  let flagLabel = enFlag;
  switch (language) {
    case 'en':
      flagLabel = enFlag;
      break;
    case 'de':
      flagLabel = deFlag;
      break;
  }

  return (
    <div className={onMobile ? css.langSwitchWrapperMobile : css.langSwitchWrapper}>
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          {<img src={flagLabel} className={css.icon} />}
        </MenuLabel>
        <MenuContent className={onMobile ? css.profileMenuContentMobile : css.profileMenuContent}>
          <MenuItem key="en">
            <div className={css.labelWrapper} onClick={() => handleLanguageSwitch('en')}>
              <img src={enFlag} className={css.icon} />
              <span className={css.mobileLabel}>
                <FormattedMessage id={'LanguageSwither.en'} />
              </span>
            </div>
          </MenuItem>
          <MenuItem key="de">
            <div className={css.labelWrapper} onClick={() => handleLanguageSwitch('de')}>
              <img src={deFlag} className={css.icon} />
              <span className={css.mobileLabel}>
                <FormattedMessage id={'LanguageSwither.de'} />
              </span>
            </div>
          </MenuItem>
        </MenuContent>
      </Menu>
    </div>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { language } = state.UI;

  return {
    currentUser,
    language,
  };
};

const mapDispatchToProps = dispatch => ({
  onHandleSwitchNewLanguage: newLanguage => dispatch(handleSwitchLanguage(newLanguage)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const LanguageSwitcher = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LanguageSwitcherComponent);

export default LanguageSwitcher;
