import React from 'react';
import { bool } from 'prop-types';
import CountFilterPlain from './CountFilterPlain';
import CountFilterPopup from './CountFilterPopup';

const CountFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? <CountFilterPopup {...rest} /> : <CountFilterPlain {...rest} />;
};

CountFilter.defaultProps = {
  showAsPopup: false,
};

CountFilter.propTypes = {
  showAsPopup: bool,
};

export default CountFilter;
