import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const { rootClassName, className, heroImageAlt } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <span className={css.visuallyHidden}>{heroImageAlt}</span>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <span className={css.titleUnderline}>
            <FormattedMessage id="SectionHero.titlePart1" />
          </span>
          <FormattedMessage id="SectionHero.titlePart2" />
          <br />
          <FormattedMessage id="SectionHero.titlePart3" />
          <br />
          <FormattedMessage id="SectionHero.titlePart4" />
          <br />
          <FormattedMessage id="SectionHero.secondTitle" />
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        <NamedLink
          name="SearchPage"
          to={{
            search:
              '?address=Deutschland&bounds=54.55431081%2C15.25617366%2C46.74669817%2C5.6249963&mapSearch=true',
          }}
          className={css.heroButton}
        >
          <FormattedMessage id="SectionHero.browseButton" />
        </NamedLink>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
