import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  LanguageSwitcher
} from '../../components';
import MenuIcon from '@mui/icons-material/Menu';
import { TopbarSearchForm } from '../../forms';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const fullMenu = isAuthenticated ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <MenuIcon className={css.menuIcon} />
        <span className={css.fullMenuLabel}>Menu</span>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent} contentClassName={css.fullMenuContent}>
        <MenuItem key="userMenu">
          <p className={css.fullMenuSublabel}>USER</p>

          <span key="NewListingPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('NewListingPage'))}
              name="NewListingPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.createListing" />
            </NamedLink>
          </span>

          <span key="InboxPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('InboxPage'))}
              name="InboxPage"
              params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.inbox" />
            </NamedLink>
          </span>

          <span key="ProfileSettingsPage2">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('ProfileSettingsPage'))}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </span>

          <span key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </span>

          <span key="Logout">
            <InlineTextButton rootClassName={css.menuItemColored} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </span>
        </MenuItem>

        <MenuItem key="staticPagesMenu">
          <p className={css.fullMenuSublabel}>MENU </p>
          <span key="LandingPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('LandingPage'))}
              name="LandingPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.landingPage" />
            </NamedLink>
          </span>

          <span key="AboutPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('AboutPage'))}
              name="AboutPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.AboutPage" />
            </NamedLink>
          </span>

          <span key="HowItWorksPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('HowItWorksPage'))}
              name="HowItWorksPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.HowItWorksPage" />
            </NamedLink>
          </span>

          {/* <MenuItem key="HelpAndDonatePage">
                        <NamedLink
                          className={classNames(css.menuItemColored, currentPageClass('HelpAndDonatePage'))}
                          name="HelpAndDonatePage"
                        >
                          <span className={css.menuItemBorder} />
                          <FormattedMessage id="TopbarDesktop.HelpAndDonatePage" />
                        </NamedLink>
                    </MenuItem> */}

          {/* <MenuItem key="PartnersPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('PartnersPage'))}
              name="PartnersPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.SupportersPage" />
            </NamedLink>
          </MenuItem> */}

          <span key="FaqPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('FaqPage'))}
              name="FaqPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.FaqPage" />
            </NamedLink>
          </span>

          <span key="SafetyCenterPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('SafetyCenterPage'))}
              name="SafetyCenterPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.SafetyCenter" />
            </NamedLink>
          </span>

          <span key="AboutPage#contact">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('AboutPage'))}
              name="AboutPage"
              to={{ hash: '#contact' }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.contact" />
            </NamedLink>
          </span>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <MenuIcon className={css.menuIcon} />
        <span className={css.fullMenuLabel}>Menu</span>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent} contentClassName={css.fullMenuContent}>
        <MenuItem key="userMenu">
          <p className={css.fullMenuSublabel}>USER</p>

          <span key="NewListingPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('NewListingPage'))}
              name="NewListingPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.createListing" />
            </NamedLink>
          </span>

          <span key="LoginPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('LoginPage'))}
              name="LoginPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.login" />
            </NamedLink>
          </span>

          <span key="SignupPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('SignupPage'))}
              name="SignupPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.signup" />
            </NamedLink>
          </span>
        </MenuItem>

        <MenuItem key="staticPagesMenu">
          <p className={css.fullMenuSublabel}>MENU </p>
          <span key="LandingPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('LandingPage'))}
              name="LandingPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.landingPage" />
            </NamedLink>
          </span>

          <span key="AboutPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('AboutPage'))}
              name="AboutPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.AboutPage" />
            </NamedLink>
          </span>

          <span key="HowItWorksPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('HowItWorksPage'))}
              name="HowItWorksPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.HowItWorksPage" />
            </NamedLink>
          </span>
          {/* 
          <MenuItem key="HelpAndDonatePage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('HelpAndDonatePage'))}
              name="HelpAndDonatePage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.HelpAndDonatePage" />
            </NamedLink>
          </MenuItem> */}

          {/* <MenuItem key="PartnersPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('PartnersPage'))}
              name="PartnersPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.SupportersPage" />
            </NamedLink>
          </MenuItem> */}

          <span key="FaqPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('FaqPage'))}
              name="FaqPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.FaqPage" />
            </NamedLink>
          </span>

          <span key="SafetyCenterPage">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('SafetyCenterPage'))}
              name="SafetyCenterPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.SafetyCenter" />
            </NamedLink>
          </span>

          <span key="AboutPage#contact">
            <NamedLink
              className={classNames(css.menuItemColored, currentPageClass('AboutPage'))}
              name="AboutPage"
              to={{ hash: '#contact' }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.contact" />
            </NamedLink>
          </span>
        </MenuItem>
      </MenuContent>
    </Menu>
  );

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {search}

      <LanguageSwitcher />

      {fullMenu}
      <NamedLink className={css.createListingLink} name="NewListingPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
      {inboxLink}
      {profileMenu}
      {/* {signupLink}
      {loginLink} */}
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
