export const setLocalStorateList = (name, list) => {
    localStorage.setItem(name, JSON.stringify(list));
}

export const getStoredList = (name) => {
    const existingList = localStorage.getItem(name);
    return existingList ? JSON.parse(localStorage.getItem(name)) : [];
}

export const storedListContainsString = (name, searchTerm) => {
    const savedList = getStoredList(name);

    return savedList?.includes(searchTerm);
}