import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators, numberAtLeast } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldDateInput, FieldCheckboxGroup } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';
import arrayMutators from 'final-form-arrays';

import css from './EditListingDescriptionForm.module.css';
import CustomFieldCapacity from './CustomFieldCapacity';

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{
      ...arrayMutators,
    }}
    render={formRenderProps => {
      const {
        categories,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        publicData,
        values,
        form,
        initialValues,
      } = formRenderProps;

      const [availableFromComparingValue, setAvailableFromComparingValue] = useState(
        initialValues.availableFrom
      );
      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessageOffer = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholderOffer',
      });
      const descriptionPlaceholderMessageRequest = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholderRequest',
      });
      const datePickFrom = intl.formatMessage({
        id: 'EditListingDescriptionForm.datePickFrom',
      });
      const datePickAvailableFrom = intl.formatMessage({
        id: 'EditListingDescriptionForm.datePickAvailableFrom',
      });
      const datePickUntill = intl.formatMessage({
        id: 'EditListingDescriptionForm.datePickUntill',
      });
      const datePickAvailableUntill = intl.formatMessage({
        id: 'EditListingDescriptionForm.datePickAvailableUntill',
      });
      const languageHost = intl.formatMessage({
        id: 'EditListingDescriptionForm.languageHost',
      });
      const languageGuest = intl.formatMessage({
        id: 'EditListingDescriptionForm.languageGuest',
      });
      const languagePlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.languagePlaceholder',
      });
      const capacityNumberOfPeople = intl.formatMessage({
        id: 'EditListingDescriptionForm.capacityNumberOfPeople',
      });
      const capacityDescriptionOffer = intl.formatMessage({
        id: 'EditListingDescriptionForm.capacityDescriptionOffer',
      });
      const capacityDescriptionRequest = intl.formatMessage({
        id: 'EditListingDescriptionForm.capacityDescriptionRequest',
      });
      const capacityDescriptionPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.capacityDescriptionPlaceholder',
      });
      const petsPresent = intl.formatMessage({
        id: 'EditListingDescriptionForm.petsPresent',
      });
      const petsInfo = intl.formatMessage({
        id: 'EditListingDescriptionForm.petsInfo',
      });
      const petsInfoPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.petsInfoPlaceholder',
      });
      const accessibilityOffer = intl.formatMessage({
        id: 'EditListingDescriptionForm.accessibilityOffer',
      });
      const accessibilityRequest = intl.formatMessage({
        id: 'EditListingDescriptionForm.accessibilityRequest',
      });
      const accessibilityInfo = intl.formatMessage({
        id: 'EditListingDescriptionForm.accessibilityInfo',
      });
      const accessibilityInfoPlaceholderOffer = intl.formatMessage({
        id: 'EditListingDescriptionForm.accessibilityInfoPlaceholderOffer',
      });
      const accessibilityInfoPlaceholderRequest = intl.formatMessage({
        id: 'EditListingDescriptionForm.accessibilityInfoPlaceholderRequest',
      });
      const assistanceAnimalsOffer = intl.formatMessage({
        id: 'EditListingDescriptionForm.assistanceAnimalsOffer',
      });
      const assistanceAnimalsRequest = intl.formatMessage({
        id: 'EditListingDescriptionForm.assistanceAnimalsRequest',
      });
      const assistanceAnimalsInfo = intl.formatMessage({
        id: 'EditListingDescriptionForm.assistanceAnimalsInfo',
      });
      const assistanceAnimalsInfoPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.assistanceAnimalsInfoPlaceholder',
      });
      const allergies = intl.formatMessage({
        id: 'EditListingDescriptionForm.allergies',
      });
      const allergiesInfo = intl.formatMessage({
        id: 'EditListingDescriptionForm.allergiesInfo',
      });
      const allergiesInfoPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.allergiesInfoPlaceholder',
      });
      const responseYes = intl.formatMessage({
        id: 'EditListingDescriptionForm.responseYes',
      });
      const responseNo = intl.formatMessage({
        id: 'EditListingDescriptionForm.responseNo',
      });

      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled =
        invalid ||
        disabled ||
        submitInProgress ||
        !values.accessibility ||
        !values.assistanceAnimals;

      const isOfferring = publicData && publicData.category === 'offeringPlace';
      const isLooking = publicData && publicData.category === 'lookingForPlace';

      const onHandleChange = formValues => {
        if (
          availableFromComparingValue.date.getDate() !==
            formValues.values.availableFrom.date.getDate() &&
          formValues.values.availableUntil.date.getDate() !==
            formValues.values.availableFrom.date.addDays(1).getDate()
        ) {
          form.change('availableUntil', {
            date: formValues.values.availableFrom.date.addDays(1),
          });
          setAvailableFromComparingValue(formValues.values.availableFrom);
        }
      };

      const isDayBlocked = day => {
        return day <= values.availableFrom.date;
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FormSpy onChange={onHandleChange} />

          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={
              isOfferring
                ? descriptionPlaceholderMessageOffer
                : descriptionPlaceholderMessageRequest
            }
            // validate={composeValidators(required(descriptionRequiredMessage))}
          />

          <div className={css.datesFieldsWrapper}>
            <FieldDateInput
              id="availableFrom"
              name="availableFrom"
              label={isLooking ? datePickFrom : datePickAvailableFrom}
              placeholderText="Datum auswählen"
              validate={composeValidators(required('Sie müssen dieses Feld ausfüllen'))}
            />

            <FieldDateInput
              id="availableUntil"
              name="availableUntil"
              label={isLooking ? datePickUntill : datePickAvailableUntill}
              placeholderText="Datum auswählen"
              className={css.availableUntil}
              validate={composeValidators(required('Sie müssen dieses Feld ausfüllen'))}
              isDayBlockedFunction={isDayBlocked}
            />
          </div>

          <FieldTextInput
            id="language"
            name="language"
            className={css.title}
            type="text"
            label={isOfferring ? languageHost : languageGuest}
            placeholder={languagePlaceholder}
          />

          <CustomFieldCapacity
            id="capacityNumberOfPeople"
            name="capacityNumberOfPeople"
            label={capacityNumberOfPeople}
          />
          {/* <FieldTextInput
            id="capacityNumberOfPeople"
            name="capacityNumberOfPeople"
            className={css.title}
            type="number"
            label={'Kapazität'}
            placeholder={'Wie viele Gäste könnten maximal beherbergt werden?'}
            validate={numberAtLeast('Sie müssen mindestens eine Kapazität von 1 eingeben', 1)}
          /> */}

          <FieldTextInput
            id="capacityDescription"
            name="capacityDescription"
            className={css.title}
            type="text"
            label={isOfferring ? capacityDescriptionOffer : capacityDescriptionRequest}
            placeholder={capacityDescriptionPlaceholder}
          />

          {isOfferring && (
            <>
              <FieldCheckboxGroup
                className={css.features}
                id={'pets'}
                name={'pets'}
                label={petsPresent}
                options={[
                  {
                    key: 'yes',
                    label: responseYes,
                  },
                  {
                    key: 'no',
                    label: responseNo,
                  },
                ]}
                twoColumns={true}
                radio={true}
              />

              {values.pets === 'yes' && (
                <FieldTextInput
                  id="petsInfo"
                  name="petsInfo"
                  className={css.title}
                  type="text"
                  label={petsInfo}
                  placeholder={petsInfoPlaceholder}
                />
              )}
            </>
          )}

          <FieldCheckboxGroup
            className={css.checkboxGroup}
            id={'accessibility'}
            name={'accessibility'}
            label={isOfferring ? accessibilityOffer : accessibilityRequest}
            options={[
              {
                key: 'yes',
                label: responseYes,
              },
              {
                key: 'no',
                label: responseNo,
              },
            ]}
            twoColumns={true}
            radio={true}
          />
          <p className={css.explanatoryText}>
            <FormattedMessage id="EditListingDescriptionForm.accessibilityExplanatoryText" />
          </p>

          {values.accessibility === 'yes' && (
            <FieldTextInput
              id="accessibilityInfo"
              name="accessibilityInfo"
              className={css.title}
              type="text"
              label={accessibilityInfo}
              placeholder={
                isOfferring
                  ? accessibilityInfoPlaceholderOffer
                  : accessibilityInfoPlaceholderRequest
              }
            />
          )}

          <FieldCheckboxGroup
            className={css.checkboxGroup}
            id={'assistanceAnimals'}
            name={'assistanceAnimals'}
            label={isOfferring ? assistanceAnimalsOffer : assistanceAnimalsRequest}
            options={[
              {
                key: 'yes',
                label: responseYes,
              },
              {
                key: 'no',
                label: responseNo,
              },
            ]}
            twoColumns={true}
            radio={true}
          />

          {values.assistanceAnimals === 'yes' && (
            <FieldTextInput
              id="assistanceAnimalsInfo"
              name="assistanceAnimalsInfo"
              className={css.title}
              type="text"
              label={assistanceAnimalsInfo}
              placeholder={assistanceAnimalsInfoPlaceholder}
            />
          )}

          {isLooking && (
            <>
              <FieldCheckboxGroup
                className={css.checkboxGroup}
                id={'allergies'}
                name={'allergies'}
                label={allergies}
                options={[
                  {
                    key: 'yes',
                    label: responseYes,
                  },
                  {
                    key: 'no',
                    label: responseNo,
                  },
                ]}
                twoColumns={true}
                radio={true}
              />

              {values.allergies === 'yes' && (
                <FieldTextInput
                  id="allergiesInfo"
                  name="allergiesInfo"
                  className={css.title}
                  type="text"
                  label={allergiesInfo}
                  placeholder={allergiesInfoPlaceholder}
                />
              )}
            </>
          )}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={!values.title || !values.accessibility}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>

          <p>
            <i>
              <FormattedMessage id="EditListingDescriptionForm.mandatoryField" />
            </i>
          </p>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
