import React, { useEffect, useState } from 'react';
import css from './EditListingDescriptionForm.module.css';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Field } from 'react-final-form';
const CustomFieldCapacity = props => {
  const { name, label } = props;
  const counterField = ({ input: { onChange, value }, label, ...rest }) => {
    return (
      <div>
        <p className={css.fieldLabel}>{label}</p>

        <div className={css.counterWrapper}>
          <RemoveIcon
            onClick={() => {
              if (Number(value) > 1) {
                onChange(Number(value) - 1);
              }
            }}
            className={css.counterIcon}
          />
          <p className={css.counterValue}>{value}</p>

          <AddIcon
            onClick={() => {
              onChange(Number(value) + 1);
            }}
            className={css.counterIcon}
          />
        </div>
      </div>
    );
  };
  return <Field name={name} label={label} component={counterField} />;
};

export default CustomFieldCapacity;
