import React, { useMemo } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';

import css from './EditListingDescriptionPanel.module.css';

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);

  const titleValue = !title || title === 'temporaryTitle' ? null : title;
  const isUserVerified = currentUser?.attributes?.profile?.publicData?.isVerified;
  const isVerifiedObj = isUserVerified
    ? {
        isVerified: 'verified',
      }
    : {};

  const initialValues = useMemo(
    () => ({
      title: titleValue,
      description,
      availableFrom: {
        date: publicData?.availableFrom?.date
          ? new Date(publicData?.availableFrom.date)
          : new Date('2024-06-14'),
      },
      availableUntil: {
        date: publicData?.availableUntil?.date
          ? new Date(publicData?.availableUntil.date)
          : new Date('2024-07-14'),
      },
      language: publicData.language,
      capacityNumberOfPeople: publicData.capacityNumberOfPeople || publicData.capacity || 1,
      capacityDescription: publicData.capacityDescription,
      pets: publicData.pets,
      petsInfo: publicData.petsInfo,
      //this needs to be saved as array to match the multi-enum st search schema
      accessibility:
        publicData.accessibility && Array.isArray(publicData.accessibility)
          ? publicData.accessibility[0]
          : null,
      accessibilityInfo: publicData.accessibilityInfo,
      //the same for this one
      assistanceAnimals:
        publicData.assistanceAnimals && Array.isArray(publicData.assistanceAnimals)
          ? publicData.assistanceAnimals[0]
          : null,
      assistanceAnimalsInfo: publicData.assistanceAnimalsInfo,
      allergies: publicData.allergies,
      allergiesInfo: publicData.allergiesInfo,
    }),
    []
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDescriptionForm
        className={css.form}
        initialValues={initialValues}
        initialValuesEqual={() => true}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            title,
            description,
            availableFrom,
            availableUntil,
            language,
            capacityNumberOfPeople,
            capacityDescription,
            pets,
            petsInfo,
            accessibility,
            accessibilityInfo,
            assistanceAnimals,
            assistanceAnimalsInfo,
            allergies,
            allergiesInfo,
          } = values;
          const updateValues = {
            title: title.trim(),
            description,
            publicData: {
              availableFrom: {
                date: availableFrom.date.toString(),
              },
              availableUntil: {
                date: availableUntil.date.toString(),
              },
              language,
              capacityNumberOfPeople: Number(capacityNumberOfPeople),
              capacityDescription,
              pets,
              petsInfo,
              accessibility: [accessibility], //this needs to be saved as array to match the multi-enum st search schema
              accessibilityInfo,
              assistanceAnimals: [assistanceAnimals], //the same for this one
              assistanceAnimalsInfo,
              allergies,
              allergiesInfo,
              ...isVerifiedObj,
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={categoryOptions}
        publicData={publicData}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
